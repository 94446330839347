<template>
    <v-container fluid>
        <v-card class="text-sm overflow-hidden px-sm-4 px-4 d-flex justify-center mx-auto">
            <v-form style="width: 100%" class="pt-10" lazy-validation ref="form">
                <v-row class="d-flex justify-center">
                    <v-col cols="12" md="6">
                        <v-text-field
                            :prepend-inner-icon="lockIcon"
                            label="Nome"
                            placeholder="Nome do canal"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-text-field
                            label="Tópico"
                            placeholder="Tópico do canal"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-row no-gutters>
                            <v-col cols="9" sm="10" md="6" class="d-flex justify-start align-center">
                                <v-list-item two-line class="pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>Privado</v-list-item-title>
                                        <v-list-item-subtitle
                                            >Apenas utilizadores convidados podem aceder a esta
                                            sala</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="3" sm="2" md="6" class="d-flex justify-md-end justify-sm-end align-center">
                                <v-switch inset dense></v-switch>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="9" sm="10" md="6" class="d-flex justify-start align-center">
                                <v-list-item two-line class="pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>Somente leitura</v-list-item-title>
                                        <v-list-item-subtitle
                                            >Todos os utilizadores no canal podem escrever novas
                                            mensagens</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="3" sm="2" md="6" class="d-flex justify-md-end justify-sm-end align-center">
                                <v-switch inset dense></v-switch>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="9" sm="10" md="6" class="d-flex justify-start align-center">
                                <v-list-item two-line class="pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>Criptografado</v-list-item-title>
                                        <v-list-item-subtitle
                                            >Canal criptografado de Ponta a ponta. A pesquisa não funcionará com canais
                                            criptografados e as notificações podem não mostrar o conteúdo das
                                            mensagens</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="3" sm="2" md="6" class="d-flex justify-md-end justify-sm-end align-center">
                                <v-switch inset dense></v-switch>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="9" sm="10" md="6" class="d-flex justify-start align-center">
                                <v-list-item two-line class="pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>Broadcast</v-list-item-title>
                                        <v-list-item-subtitle
                                            >Apenas utilizadores autorizados podem escrever novas mensagens, mas os
                                            outros utilizadores poderão responder</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="3" sm="2" md="6" class="d-flex justify-md-end justify-sm-end align-center">
                                <v-switch inset dense></v-switch>
                            </v-col>
                        </v-row>

                        <v-autocomplete
                            v-model="friends"
                            :disabled="isUpdating"
                            :items="people"
                            chips
                            color="blue-grey lighten-2"
                            label="Adicionar membros"
                            item-text="name"
                            item-value="name"
                            multiple
                            outlined
                            class="mt-4">
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="remove(data.item)">
                                    <v-avatar left>
                                        <v-img :src="data.item.avatar"></v-img>
                                    </v-avatar>
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar size="30">
                                        <img :src="data.item.avatar" />
                                    </v-list-item-avatar>
                                    <v-list-item-content class="test">
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <div class="d-flex justify-center mt-4 pb-10">
                    <v-btn type="reset" outlined class="mr-2"> Cancelar </v-btn>
                    <v-btn color="primary" class="mr-2"> Criar </v-btn>
                </div>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
    import { mdiLock } from '@mdi/js';
    export default {
        data() {
            const srcs = {
                1: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                2: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                3: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                4: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                5: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
            };

            return {
                autoUpdate: true,
                friends: [],
                isUpdating: false,

                people: [
                    { name: 'Sandra Adams', group: 'Group 1', avatar: srcs[1] },
                    { name: 'Ali Connors', group: 'Group 1', avatar: srcs[2] },
                    { name: 'Trevor Hansen', group: 'Group 1', avatar: srcs[3] },
                    { name: 'Tucker Smith', group: 'Group 1', avatar: srcs[2] },

                    { name: 'Britta Holt', group: 'Group 2', avatar: srcs[4] },
                    { name: 'Jane Smith ', group: 'Group 2', avatar: srcs[5] },
                    { name: 'John Smith', group: 'Group 2', avatar: srcs[1] },
                    { name: 'Sandra Williams', group: 'Group 2', avatar: srcs[3] },
                ],

                lockIcon: mdiLock,
            };
        },

        watch: {
            isUpdating(val) {
                if (val) {
                    setTimeout(() => (this.isUpdating = false), 3000);
                }
            },
        },

        methods: {
            remove(item) {
                const index = this.friends.indexOf(item.name);
                if (index >= 0) this.friends.splice(index, 1);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-list-item__subtitle {
        white-space: unset;
    }
</style>
